import React, { useState, useEffect } from 'react';
import './App.css';
import './assets/css/style.css'
import './assets/css/animate.css'
import './assets/css/bsnav.min.css'
import './assets/css/responsive.css'
import './assets/css/style2.css'
import './assets/css/fontawesome.min.css'
import { Swiper, SwiperSlide } from "swiper/react";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

// import required modules
import { Pagination } from "swiper";
import Booking from './Booking';
function App() {



  return (
    <div className="App">
      <header className="header">
        {/* <div className="main-navigation">
        <div className="container">
              <div className="header-logo">
                <a className="navbar-brand" href="#booking_b">
                  <img src={require('./assets/img/logo-1.png')} className="logo-display" alt="thumb" />
                </a>
                <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                  <span class="navbar-toggler-icon"></span>
                </button>
                <div class="collapse navbar-collapse" id="navbarSupportedContent">
                  <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                    <li class="nav-item">
                      <a class="nav-link active" aria-current="page" href="#">Home</a>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link" href="#">Link</a>
                    </li>

                    <li class="nav-item">
                      <a class="nav-link disabled" href="#" tabindex="-1" aria-disabled="true">Disabled</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div> */}
        <nav class="navbar navbar-expand-lg navbar-light bg-light">
          <div class="container">
            <a class="navbar-brand" href="#">
              <div className="header-logo">
                <a className="navbar-brand" href="#booking_b">
                  <img src={require('./assets/img/logo-1.png')} className="logo-display" alt="thumb" />
                </a>
              </div>
            </a>
            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
              <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarSupportedContent">
              <ul class="navbar-nav">
                <li class="nav-item">
                  <a class="nav-link" aria-current="page" href="#home">Home</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="#about">About</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="#service">Services</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="#gallery">Gallery</a>
                </li>
                {/* <li class="nav-item">
                  <a class="nav-link" href="#contact">Contact Us</a>
                </li> */}
              </ul>

            </div>
          </div>
        </nav>
      </header>
      <main className="main">

        <div id="home" className="hero-section header-2">
          <div className="hero-slider">
            <div className="hero-single home_banner" data-background={require('./assets/img/header/hdr.png')}>
              <div className="container">
                <div className="row">


                </div>
              </div>
            </div>
          </div>
          <Booking />
        </div>

        <div class="ab-1 de-padding" id='about'>
          <div class="ab-1-wrapper grid-2">
            <div class="about_us_s text_left">
              <h5>About us</h5>
              <div className='site-title m-0'>
                <h2>What We Are</h2>
              </div>

              <p>
                Children are the best boon to mankind. A smiling , happily giggling, slightly naughty child is the dream of every parent. Rearing a child is very similar to gardening. You need to provide tender care for your child flower to blossom .Sadly, a number of children suffer due to illogical and unscientific ways of child rearing practices.
              </p>
              <p>Recurrent infections, vitamin and mineral deficiencies, anemia and growth failure are some of the common side effects. Delayed diagnosis of some hormonal and heart diseases adds gravity to their suffering. We aim to provide the best child care so as not only to prevent illnesses but also to diagnose quickly the diseases for an effective and timely management.</p>

            </div>
            <div class="ab-1-right">
              <img src={require('./assets/img/img3-1.jpg')} alt="thumb" />
            </div>
          </div>
        </div>
        <div class="ts-area de-padding">
          <div class="container">
            <div class="ts-title mb-60 text-center">
              <div className='site-title m-0'>
                <h2>Our Vision</h2>
              </div>

            </div>
            <div class="ts-wrapper grid-2">
              <div class="ts-box">

                <div class="ts-btm">
                  <p>Healthy child transforms into a healthy nation. Healthy child not only means physically healthy but mentally and psychologically healthy too. It is the utmost duty of every child physician that every child who comes to him for consultation is able to achieve a complete health.</p>

                </div>
              </div>
              <div class="ts-box">

                <div class="ts-btm">
                  <p>To make the childhood illness free as much as possible and hence to let the parents enjoy the childhood of their little bunnies in a cost effective and hassle free manner.</p>

                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="ab-area de-padding">
          <div className="container">
            <div className="ab-wrapper grid-2">
              <div className="ab-left img_width">
                <img src={require('./assets/img/doctor-1.jpg')} alt="thumb" />
              </div>
              <div className="ab-right text_left">
                {/* <h5>About The Medihouse</h5> */}
                <h2>Dr. Rajesh Kumar</h2>
                <div className="ab-txts">
                  <p>
                    Chirayu child care clinic is owned and run by Dr. Rajesh Kumar in Palam vihar Gurgaon . Dr. Rajesh Kumar is an experienced pediatrician with an awesome track record of 20 years. An alumni of NSCB medical college ,Jabalpur .M.P,  he did his MBBS and MD-Pediatrics from the same college . After completion of MD degree in year 2000, he has worked in Batra hospital and medical research centre, new Delhi . He provides comprehensive child care using best of expertise and empathy. Not only routine child care issues but complex child care problems are deftly dealt with because of his vast experience in the field of pediatrics.
                  </p>
                  <p className="mb-0">
                    Our aim-patient first, helps us to envision each child’s problem as unique and thus advising patient centric treatment.
                  </p>
                  <p><strong>Affordability and excellence are our main mantra.</strong></p>
                </div>

              </div>
            </div>
          </div>
        </div>

        <div className="sv-area bg-2 de-padding" id='service'>
          <div className="container">
            <div className="row">
              <div className="col-xl-8 offset-xl-2">
                <div className="site-title text-center">
                  <h5>_Our Services_</h5>
                  <h2>Professional child care centre</h2>
                </div>
              </div>
            </div>
            <div className="sv-wrapper grid-3">
              <div className="sv-box">
                <div className="sv-icon">
                  <i><img src={require('./assets/img/icons/home-care.png')} alt="thumb" /></i>
                </div>
                <div className="sv-info">
                  <h5>Consultation</h5>
                  <p className="mb-0">
                    Consultation for various childhood illnesses including infections , hormonal problems,chronic diseases etc.
                  </p>
                </div>
              </div>
              <div className="sv-box">
                <div className="sv-icon">
                  <i><img src={require('./assets/img/icons/ope.png')} alt="thumb" /></i>
                </div>
                <div className="sv-info">
                  <h5>Nebulisation</h5>
                  <p className="mb-0">
                    Apart from providing Nebulizer service, our Certified Pharmacists provide Health tips so that you will be in Good Health.
                  </p>
                </div>
              </div>
              <div className="sv-box">
                <div className="sv-icon">
                  <i><img src={require('./assets/img/icons/care.png')} alt="thumb" /></i>
                </div>
                <div className="sv-info">
                  <h5>Vaccinations</h5>
                  <p className="mb-0">
                    Vaccinations of all types including newly available and painless ones. We offer up-to-date advice & services
                  </p>
                </div>
              </div>
              {/* <div className="sv-box">
                <div className="sv-icon">
                  <i><img src={require('./assets/img/icons/te.png')} alt="thumb" /></i>
                </div>
                <div className="sv-info">
                  <h5>Laboratory Test</h5>
                  <p className="mb-0">
                    Excepteur cras, earum ad rhoncus inceptos conubia ligula quod.
                  </p>
                </div>
              </div> */}
              <div className="sv-box">
                <div className="sv-icon">
                  <i><img src={require('./assets/img/icons/medicine.png')} alt="thumb" /></i>
                </div>
                <div className="sv-info">
                  <h5>Preconceptional advice</h5>
                  <p className="mb-0">
                    Preconceptional advice for second child for parents with children suffering from genetic diseases.
                  </p>
                </div>
              </div>
              <div className="sv-box">
                <div className="sv-icon">
                  <i><img src={require('./assets/img/icons/ambulance.png')} alt="thumb" /></i>
                </div>
                <div className="sv-info">
                  <h5>Skin Diseases </h5>
                  <p className="mb-0">
                    We delivers specialty care to your kids suffering from diseases or conditions affecting the skin.
                  </p>
                </div>
              </div>
              <div className="sv-box">
                <div className="sv-icon">
                  <i><img src={require('./assets/img/icons/emergency.png')} alt="thumb" /></i>
                </div>
                <div className="sv-info">
                  <h5>Parenting advice</h5>
                  <p className="mb-0">
                    Parenting is one of the most important tasks we undertake. There are a range of support services to help parents.
                  </p>
                </div>
              </div>
              {/* <div className="sv-box">
                <div className="sv-icon">
                  <i><img src={require('./assets/img/icons/te.png')} alt="thumb" /></i>
                </div>
                <div className="sv-info">
                  <h5>Laboratory Test</h5>
                  <p className="mb-0">
                    Excepteur cras, earum ad rhoncus inceptos conubia ligula quod.
                  </p>
                </div>
              </div> */}
            </div>
          </div>
        </div>

        <div className="ab-area de-pt">
          <div className="container">
            <div className="ab-wrapper grid-2">
              <div className="ab-left">
                <img src={require('./assets/img/img-1.jpg')} alt="thumb" />
              </div>
              <div className="ab-right text_left">
                {/* <h5>About The Medihouse</h5> */}
                <div className='site-title m-0'> <h2>Professional care</h2></div>

                <div className="ab-txts">
                  <p>Chirayu child care clinic is a clinic with child friendly approach near you. We strive to keep little angels illness free and to help them recover fast when unfortunate one strikes.</p>
                  <p>Children fall prey to illnesses due to various infections ,malnutrition (a child with normal weight and even an overweight child may still be suffering from malnutrition due to faulty eating habits),unscientific child rearing practices, family rituals etc.</p>
                  <p>Our job is to provide you scientific information and to bring about life style changes so as to minimize illness.</p>
                </div>

              </div>
            </div>
          </div>
        </div>
        <div className="ab-area de-padding">
          <div className="container">
            <div className="ab-wrapper grid-2">

              <div className="ab-right text_left">
                {/* <h5>About The Medihouse</h5> */}
                <h2>Child care solution at its best</h2>
                <div className="ab-txts">
                  <p>Years of experience and passion for excellence helps us to provide convenient and affordable child care services .We treat a large no. of child care problems with high level of expertise and confidence.</p>
                  <p>We offer treatment for acute and chronic gastroenteritis, typhoid, jaundice, pneumonia, bronchial asthma, allergies, skin diseases, behavioral problems ,hormonal problems ,all types of infections including respiratory, urinary, ear nose throat and,gastrointestinal infections. Adolescent problems, Short height, failure to gain weight.</p>
                  <p><strong>All vaccinations including the latest ones and vaccines for adolescent patients  are routinely done here so as to minimize the chances of infections</strong></p>
                </div>

              </div>
              <div className="ab-left">
                <img src={require('./assets/img/img-2-1.jpg')} alt="thumb" />
              </div>
            </div>
          </div>
        </div>

        <div className="team-area ab-area de-pb" id='gallery'>
          <div className="container">
            <div className="row">
              <div className="col-xl-8 offset-xl-2">
                <div className="site-title text-center">
                  <h2>Gallery of Our Clinic</h2>
                </div>
              </div>
            </div>
            <div className="team-wrapper grid-4">
              <div className="team-box">
                <div className="team-pic">
                  <img src={require('./assets/img/gallery.jpg')} alt="thumb" />

                </div>
              </div>
              <div className="team-box">
                <div className="team-pic">
                  <img src={require('./assets/img/gallery1.jpg')} alt="thumb" />

                </div>
              </div>
              <div className="team-box">
                <div className="team-pic">
                  <img src={require('./assets/img/gallery2.jpg')} alt="thumb" />

                </div>
              </div>
              <div className="team-box">
                <div className="team-pic">
                  <img src={require('./assets/img/gallery3.jpg')} alt="thumb" />

                </div>
              </div>
              <div className="team-box">
                <div className="team-pic">
                  <img src={require('./assets/img/gallery4.jpg')} alt="thumb" />

                </div>
              </div>
              <div className="team-box">
                <div className="team-pic">
                  <img src={require('./assets/img/gallery5.jpg')} alt="thumb" />

                </div>
              </div>
              <div className="team-box">
                <div className="team-pic">
                  <img src={require('./assets/img/gallery6.jpg')} alt="thumb" />

                </div>
              </div>
              <div className="team-box">
                <div className="team-pic">
                  <img src={require('./assets/img/gallery7.jpg')} alt="thumb" />

                </div>
              </div>

            </div>
          </div>
        </div>
        <div className="site-title text-center">
          <h2>Location of Our Clinic</h2>
        </div>

        <div className='mb-5 container'>
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3506.0299643232283!2d77.02876517742378!3d28.50874618968753!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d19f4916f5bfb%3A0x2f50155254b5e0fa!2sChirayu%20Mother%20and%20Child%20Care%20Centre!5e0!3m2!1sen!2sin!4v1725341958666!5m2!1sen!2sin" width="100%" height="300" style={{ border: '0' }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        </div>

        {/*
        <div className="team-area de-padding">
          <div className="container">
            <div className="row">
              <div className="col-xl-8 offset-xl-2">
                <div className="site-title text-center">
                  <h5>_Doctors Team_</h5>
                  <h2>Our experienced doctors</h2>
                  <p className="mb-0 mt-20">
                    We have been providing Medi House professional services since 1987. With success we care for the patients with utmost care. This is very important to us.
                  </p>
                </div>
              </div>
            </div>
            <div className="team-wrapper grid-4">
              <div className="team-box">
                <div className="team-pic">
                  <img src={require('./assets/img/team/t-2.jpg')} alt="thumb" />
                  <div className="team-overlay">
                    <div className="team-bio">
                      <a href="javascript:void(0);"><h5>DR. Alena Morgan</h5></a>
                      <span>MBBS of Pathology, USA</span>
                    </div>
                    <div className="team-social">
                      <ul className="team-scl-list">
                        <li><a href="#"><i className="fab fa-facebook-f"></i></a></li>
                        <li><a href="#"><i className="fab fa-instagram"></i></a></li>
                        <li><a href="#"><i className="fab fa-twitter"></i></a></li>
                        <li><a href="#"><i className="fab fa-youtube"></i></a></li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="team-box">
                <div className="team-pic">
                  <img src={require('./assets/img/team/t-3.jpg')} alt="thumb" />
                  <div className="team-overlay">
                    <div className="team-bio">
                      <a href="javascript:void(0)"><h5>DR. Alena Morgan</h5></a>
                      <span>MBBS of Pathology, USA</span>
                    </div>
                    <div className="team-social">
                      <ul className="team-scl-list">
                        <li><a href="#"><i className="fab fa-facebook-f"></i></a></li>
                        <li><a href="#"><i className="fab fa-instagram"></i></a></li>
                        <li><a href="#"><i className="fab fa-twitter"></i></a></li>
                        <li><a href="#"><i className="fab fa-youtube"></i></a></li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="team-box">
                <div className="team-pic">
                  <img src={require('./assets/img/team/t-2.jpg')} alt="thumb" />
                  <div className="team-overlay">
                    <div className="team-bio">
                      <a href="javascript:void(0)"><h5>DR. Alena Morgan</h5></a>
                      <span>MBBS of Pathology, USA</span>
                    </div>
                    <div className="team-social">
                      <ul className="team-scl-list">
                        <li><a href="#"><i className="fab fa-facebook-f"></i></a></li>
                        <li><a href="#"><i className="fab fa-instagram"></i></a></li>
                        <li><a href="#"><i className="fab fa-twitter"></i></a></li>
                        <li><a href="#"><i className="fab fa-youtube"></i></a></li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="team-box">
                <div className="team-pic">
                  <img src={require('./assets/img/team/t-3.jpg')} alt="thumb" />
                  <div className="team-overlay">
                    <div className="team-bio">
                      <a href="javascript:void(0)"><h5>DR. Alena Morgan</h5></a>
                      <span>MBBS of Pathology, USA</span>
                    </div>
                    <div className="team-social">
                      <ul className="team-scl-list">
                        <li><a href="#"><i className="fab fa-facebook-f"></i></a></li>
                        <li><a href="#"><i className="fab fa-instagram"></i></a></li>
                        <li><a href="#"><i className="fab fa-twitter"></i></a></li>
                        <li><a href="#"><i className="fab fa-youtube"></i></a></li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        {/* <div class="c-us-area bg de-padding" id='contact'>
          <div class="container">
            <div class="row">
              <div class="col-xl-8 offset-xl-2">
                <div class="site-title text-center">
                  <h2>Send Message</h2>
                  <p class="mt-10">
                    We have been providing Medi House professional services since 1987. With success we care for the patients with utmost care. This is very important to us.
                  </p>
                </div>
              </div>
            </div>
            <div class="c-us-wpr">
              <div class="row">
                <div class="col-xl-8 offset-xl-2">
                  <form>
                    <div class="row ">
                      <div class="form-group col-md-6 mb-3 text_left">
                        <label for="inputAddress">Name</label>
                        <input type="text" class="form-control" id="inputAddress" />
                      </div>
                      <div class="form-group col-md-6 mb-3 text_left">
                        <label for="inputEmail4">Email</label>
                        <input type="email" class="form-control" id="inputEmail4" />
                      </div>
                    </div>
                    <div class="row">
                      <div class="form-group col-md-12 mb-3 text_left">
                        <label for="inputCity">Phone Number</label>
                        <input type="text" class="form-control" id="inputCity" />
                      </div>

                    </div>
                    <div class="form-group text_left">
                      <label for="exampleFormControlTextarea1">Type Your Message</label>
                      <textarea class="form-control" id="exampleFormControlTextarea1" rows="3"></textarea>
                    </div>
                    <button type="submit" class="bt">Contact</button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div> */}
      </main>
      <footer className="footer-2">
        <div className="container">

          <div className="footer-widget">
            <div className="footer-widget-wrapper grid-3 de-padding">
              <div className="footer-widget-box ab-us">
                <h4 className="foo-widget-title">Reach Us</h4>
                <p>
                  C53, 1st Floor, Palam Vyapar Kendra, Palam Vihar Gurugram, Haryana 122017
                </p>
                <div className="footer-addr">
                  <ul>
                    <li><i className="fas fa-envelope-open"></i> chirayuchildcareclinic@gmail.com</li>
                    <li><i className="fas fa-phone"></i>Phone: +91 98110 29499</li>
                  </ul>
                </div>
                {/* <ul className="footer-social">
                  <li><a href="#"><i className="fab fa-facebook-f"></i></a></li>
                  <li><a href="#"><i className="fab fa-instagram"></i></a></li>
                  <li><a href="#"><i className="fab fa-twitter"></i></a></li>
                  <li><a href="#"><i className="fab fa-youtube"></i></a></li>
                </ul> */}
              </div>

              <div className="footer-widget-box footer-cat">
                <h4 className="foo-widget-title">Helpful Links</h4>
                <ul className="foo-list">
                  <li><a href="#home">Home</a></li>
                  <li>
                    <a href="#about">About</a>
                  </li>
                  <li>
                    <a href="#service">Services</a>
                  </li>
                  <li>
                    <a href="#gallery">Gallery</a>
                  </li>
                  {/* <li>
                  <a href="#contact">Contact Us</a>
                </li> */}

                </ul>
              </div>
              <div className="footer-widget-box footer-link">
                <h4 className="foo-widget-title">Opening Hours</h4>
                <ul className="ads">
                  <li>Monday to Saturday</li>
                  <li><span>Morning  </span><span>10 am to 1.30 pm </span></li>
                  <li><span>Evening </span><span>6 pm to 9 pm </span></li>
                  <hr />
                  <li><span>Sunday </span><span>11 am to 1 pm</span></li>

                </ul>
                {/* <div className="subscribe">
                  <form>
                    <input type="text" placeholder="Type Your Email" />
                    <button type="submit"><i className="fas fa-location-arrow"></i></button>
                  </form>
                </div> */}
              </div>
            </div>
          </div>
        </div>
        <div className='copy_right'>
          <p>© Copyright 2023 Chirayu child care. All Rights Reserved. Website By <a href="https://www.rajmith.com/">Rajmith</a></p>
        </div>
      </footer>
      <ToastContainer />
    </div>

  );
}

export default App;
